import { Loading } from 'components/Loading';
import { AppController } from 'containers';
import { PrivateLayout } from 'layouts';
import {
  compose, 
  values,
  filter,
  propEq,
 } from 'ramda';
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { ServicesContextProvider } from 'services/ServicesContext';

const privateRoutes = compose(
  filter(propEq('requiredLogin', true)),
  values,
)(routes);

const PostLoginRoute = () => (
  <ServicesContextProvider>
    <AppController>
      <PrivateLayout>
        <Suspense fallback={<Loading text='Loading, please wait...' />}>
          <Switch>
            {privateRoutes.map(({ path, component }) => (
              <Route exact key={path} path={path} component={component} />
            ))}
          </Switch>
        </Suspense>
      </PrivateLayout>
    </AppController>
  </ServicesContextProvider>
);

export default PostLoginRoute;
