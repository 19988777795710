import { Typography, Box, AppBar, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { DesignButton } from 'components';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OokeengaINOInventory from './OokeengaINOInventory';
import HowToBuy from './HowToBuy';
import { styled } from '@mui/system';
import { projectData } from '../Data';
import { DateTime } from 'luxon';
import NumberFormat from 'react-number-format';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  [`& .${tabsClasses.root}, .MuiTabs-scroller`]: {
    padding: '24px !important',
    margin: '-24px !important',
  },
  [`& .${tabsClasses.flexContainer}`]: {
    flexWrap: 'wrap',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  height: '60px',
  fontSize: '20px',
  paddingLeft: '38px',
  paddingRight: '38px',
  background: `url(${require(`assets/components/gray_medium.png`)})`,
  backgroundSize: '100% 100%',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  marginBottom: '6px',
  fontFamily: 'Skranji',
  color: '#B7A284',
  '&.Mui-selected': {
    background: `url(${require(`assets/components/green_medium.png`)})`,
    backgroundSize: '100% 100%',
    fontFamily: 'Skranji',
    color: '#FFFFFF',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const OokeengaINOTabs = ({ receivedTimeFromServer }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatTime = (timeISO) => {
    return DateTime.fromISO(timeISO).toUTC().toFormat('dd MMMM yyyy HH:mm');
  };

  return (
    <div className='w-full p-9 mt-16 rounded-lg' style={{ backgroundColor: 'rgba(23, 10, 2, 0.8)' }}>
      <StyledAppBar position='static' className='p-0'>
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary' textColor='inherit'>
          <StyledTab label='Sale details' {...a11yProps(0)} />
          <StyledTab label='Project information' {...a11yProps(1)} />
          <StyledTab label='How to buy' {...a11yProps(2)} />
        </Tabs>
      </StyledAppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel className='bg-inherit' value={value} index={0} dir={theme.direction}>
        <div className='w-full' style={{ border: '1px rgba(183, 162, 132, 0.3) solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Total Raise:',
                info: `$75,000`,
              },
              {
                title: 'Start Time:',
                info: `22 April 2022 13:00 (UTC)`,
              },
              {
                title: 'End Time:',
                info: `24 April 2022 13:00 (UTC)`,
              },
              {
                title: 'Network:',
                info: 'INO & distribution on BNB Chain',
              },
              {
                title: 'Distribution:',
                info: 'Airdropped',
              },
            ].map((item, index) => (
              <div
                key={index}
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px rgba(183, 162, 132, 0.3) solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#B7A284' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#F5E6D5' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <OokeengaINOInventory />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <HowToBuy />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
export default OokeengaINOTabs;
