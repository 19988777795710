import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { QueryClientProvider } from 'react-query';
import { AppTheme } from 'containers';

import { queryClient } from 'services/client';
import NavigationRoute from 'navigations';

import './App.scss';

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppTheme>
        <BrowserRouter>
          <NavigationRoute />
        </BrowserRouter>
      </AppTheme>
    </QueryClientProvider>
  );
};
