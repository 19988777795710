import { useAuth } from 'utils';

import { Loading } from '../components/Loading';
import PostLoginRoute from './PostLoginRoute';
import PreLoginRoute from './PreLoginRoute';

const NavigationRoute = () => {
  const auth = useAuth();
  
  if (auth.isLoading) {
    return <Loading text='Loading, please wait' />;
  } else if (auth.isLoggedIn) {
    return <PostLoginRoute />;
  } else {
    return <PreLoginRoute />;
  }
};

export default NavigationRoute;
