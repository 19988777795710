import { CircularProgress } from '@mui/material';
import { erc20Contract, web3 } from 'contracts/contract';
import { DateTime } from 'luxon';
import { tap } from 'ramda';
import { sum, compose, when, values } from 'ramda';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useServicesContext } from 'services/ServicesContext';

const PopupCart = ({ onClose, onSuccess, onFailed, onRefresh }) => {
  const { marketService, storageService } = useServicesContext();
  const { address } = useSelector(({ profile }) => profile);
  const { handleSubmit, setValue } = useForm();

  const getCountdown = () => {
    const { createdAt } = storageService.getOrder();
    return DateTime.fromISO(createdAt).plus({ minutes: 15 }).diff(DateTime.now(), ['minutes', 'seconds']).toObject();
  };

  const [{ currentPrice, totalPrice, productName, ...order }] = React.useState(storageService.getOrder);
  const { mutate: submitOrder } = useMutation(marketService.submitOrder, {
    onSuccess: () => {
      onClose();
      onSuccess();
      onRefresh();
      storageService.clearOrder();
    },
  });

  const handleClickSubmit = handleSubmit((values) => {
    submitOrder({
      order_id: order.orderId,
      transaction_hash: values.transactionHash,
      email: values.email,
    });
  });

  const onCancel = () => {
    onClose();
    storageService.clearOrder();
    marketService.cancelOrder({ order_id: order.orderId }).finally(onRefresh);
    onFailed();
  };

  useEffect(() => {
    const intervalHandler = setInterval(() => {
      compose(
        when((_) => _ <= 0, onCancel),
        sum,
        values,
        // tap((_) => console.log('countdown', _)),
        getCountdown,
      )();
    }, 1000);
    return () => {
      clearInterval(intervalHandler);
    };
  }, [getCountdown]);

  const transfer = React.useCallback(async () => {
    try {
      const payment_token = currentPrice?.payment_token;

      if (payment_token.address) {
        const price = (totalPrice.toFixed(2) * 10 ** payment_token.decimal).toLocaleString('fullwide', {
          useGrouping: false,
        });
        const tx = erc20Contract(payment_token.address).methods.transfer(
          '0x063E6329BDeA016e110dcBCA76A24c0F50b13e38',
          price,
        );
        const transfer = await tx.send({ from: address });
        setValue('transactionHash', transfer.transactionHash);
        handleClickSubmit();
      } else {
        const transfer = await web3.eth.sendTransaction({
          from: address,
          to: '0x063E6329BDeA016e110dcBCA76A24c0F50b13e38',
          value: web3.utils.toWei(String(totalPrice), 'ether'),
        });

        setValue('transactionHash', transfer.transactionHash);
        handleClickSubmit();
      }
    } catch (error) {
      // if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
      onCancel();
      // }
      console.log('error', error);
    } finally {
    }
  }, [setValue, address, totalPrice, currentPrice]);

  React.useEffect(() => {
    transfer();
  }, [transfer]);

  return (
    <div className='box-border text-white p-6 overflow-x-hidden'>
      <div className='flex flex-col justify-center items-center'>
        <CircularProgress />
        <h1 className='mt-12 mb-6 text-center text-2xl font-bold'>CHECK OUT</h1>
        <p
          className='text-center text-sm'
          style={{
            color: '#D5D5DC',
          }}
        >
          Please complete your payment within <b className='text-white'>15 minutes</b>.
          <br />
          If you fail to make the transaction, your order will be automatically rejected. You can view order details in
          your Profile.
        </p>
      </div>
    </div>
  );
};

export default PopupCart;
