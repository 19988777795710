import React, { memo, useCallback } from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/system';

const NoButton = styled(Button)(({ theme }) => ({
  background: '#EBECF0',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  background: '#000',
}));

export const PopupConfirm = memo(
  ({
    title = 'Cancel?',
    message = 'Are you sure you want to cancel?',
    noBtnText = 'No',
    yesBtnText = 'Yes',
    onAnswer,
    open,
    onClose,
  }) => {
    const onYes = useCallback(() => {
      onAnswer(true);
    }, [onAnswer]);

    const onNo = useCallback(() => {
      onAnswer(false);
    }, [onAnswer]);
    return (
      <StyledDialog open={open} onClose={onClose} maxWidth='md'>
        <div className='box-border text-center text-white px-20 py-10'>
          <div className='flex justify-center'>
            <img src='/assets/imgs/xl-icons/warning.svg' width='57' height='54' alt='warning icon' />
          </div>

          <Typography variant='h3' className='my-2'>
            {title}
          </Typography>

          <Typography variant='body2' color='silver'>
            {message}
          </Typography>

          <div className='mt-4 grid grid-flow-col gap-4'>
            <Button onClick={onYes}>{yesBtnText}</Button>
            <NoButton onClick={onNo}>{noBtnText}</NoButton>
          </div>
        </div>
      </StyledDialog>
    );
  },
);
