import { Grid, Typography } from '@mui/material';
import React from 'react';
import { OokeengaINORightCard, WhitelistCard } from 'views/OokeengaINO/components';
import { OokeengaINOTabs } from './components';
import { SupportButton } from 'components';
import { isTest, projectData } from './Data';
import { useServicesContext } from 'services/ServicesContext';
import { useQuery } from 'react-query';

const OokeengaINOLanding = () => {
  const { projectConfig, marketService } = useServicesContext();
  //get TIme campaign
  const { data: receivedTimeFromServer = {} } = useQuery(
    [`${projectConfig.alias}_marketService.fetchCampaignsTime`],
    () => marketService.fetchCampaignsTime(),
    {
      refetchOnMount: 'always',
      refetchInterval: 60 * 1000,
      staleTime: 0,
      enabled: !isTest,
    },
  );

  return (
    <div className='text-white bg-no-repeat' style={{ backgroundSize: 'contain' }}>
      <SupportButton />
      <div
        className='p-4 lg:px-20 lg:pt-8 flex flex-col items-center justify-center'
        style={{
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid
          container
          spacing={4}
          className='flex justify-center items-start'
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={4} xl={3.5} className='xl:sticky top-1'>
            <OokeengaINORightCard time={receivedTimeFromServer} />
          </Grid>
        </Grid>
        <div
          columns={12}
          className='flex justify-center items-start mt-10'
          style={{
            maxWidth: '1500px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default OokeengaINOLanding;
