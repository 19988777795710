import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConnectButton,
  NetworkBar,
  // SubscribeEmail,
} from 'components';
import { usePopover } from 'hooks';
import { AppBar, Link as MuiLink, ListItemButton, Popover, Toolbar, Button } from '@mui/material';
import { connectWallet } from 'reducers/profileAction';
import { signOut } from 'reducers/profileSlice';
import { shorten } from 'utils/common';
import { routes } from 'routes';
import { useServicesContext } from 'services/ServicesContext';
import { styled } from '@mui/system';

const StyledPopover = styled(Popover)(({ theme }) => ({
  opacity: 1,
  '& .MuiPaper-elevation': {
    width: '180px',
    fontFamily: 'Avenir !important',
    color: '#F5E6D5 !important',
    borderRadius: '10px !important',
    border: '1px solid #FFFFFF !important',
    backgroundColor: '#544940 !important',
    padding: '8px 0px 8px 0px !important',
  },
}));

const isNetwork = true;

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const servicesContext = useServicesContext();
  const [isOpen, anchor, onClick, onClose] = usePopover();

  const onConnectWallet = useCallback(() => connectWallet(servicesContext), [servicesContext]);

  const onOrderHistoryClicked = () => {
    history.push('/order-history');
  };

  return (
    <div className='relative'>
      <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#262627' }}>
        <Toolbar>
          <Link to={routes.home.path}>
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
          </Link>

          <div className='mx-6'>{isNetwork && <NetworkBar visible />}</div>

          <div className='flex-1 flex items-center justify-end'>
            <MuiLink
              href='https://staking.spores.app/'
              className='sm:inline hidden hover:text-white mr-12 font-bold text-lg'
              style={{ color: '#ffffff'}}
            >
              STAKING
            </MuiLink>
            <MuiLink
              href='https://launchpad.spores.app'
              className='sm:inline hidden hover:text-white mr-12 font-bold text-lg'
              style={{ color: '#ffffff'}}
            >
              LAUNCHPAD
            </MuiLink>
            {isNetwork && (
              <div className='flex items-center'>
              {!isLoggedIn ? (
                <Button onClick={onConnectWallet}>Connect wallet</Button>
              ) : (
                <div>
                  <Button
                    size='small'
                    variant='text'
                    className='text-gray-300 text-sm px-4'
                    style={{ backgroundColor: '#3C3C3E', border: '1px solid #767679' }}
                    onClick={onClick}
                  >
                    {shorten(address)}
                  </Button>
                  <Popover open={isOpen} anchorEl={anchor} onClose={onClose}>
                    <ListItemButton
                      className='text-sm'
                      onClick={onOrderHistoryClicked}
                      style={{ backgroundColor: '#3C3C3E' }}
                    >
                      Order history
                    </ListItemButton>
                    <ListItemButton
                      style={{ backgroundColor: '#3C3C3E' }}
                      className='text-sm'
                      onClick={() => {
                        onClose();
                        navigator.clipboard.writeText(address);
                      }}
                    >
                      Copy Address
                    </ListItemButton>
                    <ListItemButton
                      style={{ backgroundColor: '#3C3C3E' }}
                      className='text-sm'
                      onClick={() => {
                        onClose();
                        dispatch(signOut());
                      }}
                    >
                      Disconnect
                    </ListItemButton>
                  </Popover>
                </div>
              )}
            </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* <SubscribeEmail /> */}
    </div>
  );
};

export default Header;
