import { SnackbarProvider } from 'notistack';

export const withSnackbar = (Component) => (props) =>
  (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ marginTop: 60 }}
    >
      <Component {...props} />
    </SnackbarProvider>
  );
