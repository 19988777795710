import { Button, Dialog, LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { erc20Contract, web3 } from 'contracts/contract';
import { usePopup } from 'hooks';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { PopupCart, PopupDone, PopupFailed } from 'views/Checkout';
import { useDebounce } from 'use-debounce';
import { useServicesContext } from 'services/ServicesContext';
import { isNil } from 'ramda';
import { connectWallet } from 'reducers/profileAction';

export const TotalRaiseProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: '#222325',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    background: 'linear-gradient(270deg, rgba(255, 239, 94, 1) 0%, rgba(247, 147, 111, 1) 100%)',
  },
}));

export const ButtonCurrency = styled(Button)(({ theme }) => ({
  background: '#191B1E',
  '&:hover': {
    background: '#3C3C3E',
  },
  height: '36px',
  width: '75px',
  boxSizing: 'border-box',
  borderRadius: '8px',
  color: '#9994A3',
  fontSize: '14px',
  '&.active': {
    color: '#EECE7C',
    background: '#3C3C3E',
    border: '1px solid #EECE7C',
    '&:hover': {
      background: '#3C3C3E',
    },
  },
}));

function OokeengaINORightCard({ time }) {
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const servicesContext = useServicesContext();
  const onConnectWallet = useCallback(() => connectWallet(servicesContext), [servicesContext]);
  const [currency, setCurrency] = React.useState({
    name: 'USDT',
    index: 0,
  });
  const handClickCurrency = (event, index) => {
    setCurrency({ name: event.target.value, index: index });
  };

  const { projectConfig, marketService, storageService } = useServicesContext();
  //get Quota of user level
  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    [`${projectConfig.apiBaseUrl}_marketService.getQuota`],
    () => marketService.getQuota(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );

  const vipLevel = quota.name;

  const isAdmin = useMemo(() => vipLevel === 'VIP 0', [vipLevel]);

  //get list Product
  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    {
      refetchOnMount: 'always',
    },
  );

  const products = React.useMemo(() => {
    return data
      .sort((a, b) => a.product_id - b.product_id)
      .map((item) => {
        return {
          product_id: item?.product_id,
          prices: item?.product?.price,
          name: item?.product.name,
          remain_amount: item?.remain_quantity,
          amount: item?.product.amount,
        };
      });
  }, [data]);

  const amountLeft = products[0]?.remain_amount;
  const productName = products[0]?.name;
  const spoPrice = products[0]?.prices[0]?.price;

  //get product selected
  const product = React.useMemo(() => {
    let arr = products;
    return { ...arr[0] };
  }, [products]);
  //fetch new user level and product
  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
  };
  //amount quantity of item
  const [amounts, setAmounts] = React.useState(null);

  const totalPrice = amounts;

  //dialog
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();
  const [isOpenFailed, onOpenFailed, onCloseFailed] = usePopup();

  //checkout
  const currentPrice = product?.prices?.[currency.index];
  const { mutate: checkout } = useMutation(marketService.checkout, {
    onSuccess: (data, body) => {
      const { order } = data;
      storageService.saveOrder({
        orderId: order.id,
        createdAt: order.CreatedAt,
        ...body,
        discount: 0,
        totalDiscount: 0,
        currentPrice,
        totalPrice: totalPrice,
        productName: productName,
      });

      setTimeout(() => {
        onOpenCart();
        onRefresh();
      }, 0);
    },
  });
  const [isEnoughBalance, setIsEnoughBalance] = React.useState(true);

  const [debouncedAmounts] = useDebounce(amounts, 500);

  const checkBalance = useCallback(async () => {
    const { payment_token } = currentPrice;
    const balance = payment_token?.address
      ? await erc20Contract(payment_token?.address).methods.balanceOf(address).call()
      : await web3.eth.getBalance(address);
    const isEnough = Number(balance) >= totalPrice * 10 ** (payment_token.decimal || 18);
    return isEnough;
  }, [address, currentPrice, totalPrice]);

  const updateUiStateAfterCheckBalance = () => {
    setIsEnoughBalance(false);
    setTimeout(() => {
      setIsEnoughBalance(true);
    }, 5000);
  };

  useEffect(() => {
    if (!debouncedAmounts) return;
    checkBalance().then((isEnough) => {
      if (!isEnough && !isAdmin) {
        updateUiStateAfterCheckBalance();
      }
    });
  }, [debouncedAmounts, checkBalance, isAdmin]);

  const handleClickCheckout = async () => {
    const isEnough = await checkBalance();
    if (!isEnough && !isAdmin) {
      updateUiStateAfterCheckBalance();
      return;
    }

    checkout({
      products: [
        {
          product_id: 1,
          amount: amounts,
        },
      ],
      currency: currency.name,
    });
  };

  const onBlur = useCallback(() => {
    if (!amounts) setAmounts(null);
    // if (currentRound < 3 && amounts > userAllocation) setAmounts(userAllocation);
    // else if (currentRound === 3 && amounts > amountLeft) setAmounts(amountLeft);
  }, [amounts, setAmounts]);

  const isSoldOut = useMemo(() => amountLeft <= 0, [amountLeft]);

  const correctAmounts = useMemo(() => {
    if (isNil(amounts)) return true;
    if (amounts <= amountLeft) return true;
    return false;
  }, [amounts, amountLeft]);

  const cantBuy = useMemo(() => {
    const canBuy = isEnoughBalance && correctAmounts;
    return !canBuy;
  }, [isEnoughBalance, correctAmounts]);

  return (
    <div className='p-7 rounded-lg' style={{ backgroundColor: '#2C2C2E', color: '#87878D' }}>
      <div>
        <div className='flex space-x-4 mb-4 justify-center'>
          <div>
            <div style={{ fontSize: '24px' }} className='text-white font-bold'>
              OTC TRADING
            </div>
          </div>
        </div>

        <Dialog open={isOpenCart} maxWidth='xs'>
          <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} onFailed={onOpenFailed} onRefresh={onRefresh} />
        </Dialog>
        {isOpenDone && (
          <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='xs'>
            <PopupDone onClose={onCloseDone} />
          </Dialog>
        )}
        {isOpenFailed && (
          <Dialog open={isOpenFailed} onClose={onCloseFailed} maxWidth='xs'>
            <PopupFailed onClose={onCloseFailed} />
          </Dialog>
        )}
      </div>
      <div>
        <div className='flex flex-col w-full'>
          <div className='mb-3 flex justify-between items-center'>
            <span className=''>Select currency</span>
            <div className='flex flex-row mt-1 py-1 rounded-lg' style={{ background: '#191B1E' }}>
              {product?.prices?.map((item, index) => (
                <ButtonCurrency
                  key={index}
                  className={item.payment_token.name === currency.name ? 'active mx-1' : 'mx-1'}
                  value={item.payment_token.name}
                  onClick={(e) => handClickCurrency(e, index)}
                >
                  {item.payment_token.name}
                </ButtonCurrency>
              ))}
            </div>
          </div>
          <span className='mb-1'>I want to pay</span>
          <div className='mb-4'>
            <div
              style={{ height: '62px', background: '#191B1E', borderRadius: '8px' }}
              className='flex justify-between p-4 text-white'
            >
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle='thousand'
                value={amounts}
                onValueChange={({ floatValue: value }) => setAmounts(value)}
                onBlur={onBlur}
                allowNegative={false}
                decimalScale={0}
                defaultValue={0}
                className='text-start bg-transparent text-2xl font-bold'
                style={{ maxWidth: '60%', color: '#FAFAFA' }}
              />
              <span
                className='text-end bg-transparent text-2xl font-bold'
                style={{ color: '#87878D' }}
              >{`${currency.name}`}</span>
            </div>
          </div>

          <span className='mb-1'>I will receive</span>
          <div className='mb-4'>
            <div
              style={{ height: '62px', background: '#3C3C3E', borderRadius: '8px' }}
              className='flex justify-between p-4 text-white'
            >
              <span className='text-end bg-transparent text-2xl font-bold' style={{ color: '#FAFAFA' }}>{`${
                amounts
                  ? parseFloat((totalPrice / spoPrice).toFixed(5))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : 0
              }`}</span>
              <span className='text-end bg-transparent text-2xl font-bold' style={{ color: '#87878D' }}>
                SPO
              </span>
            </div>
          </div>

          <div className='mb-4 flex'>
            <span className='font-medium text-xs'>
              1 SPO = {parseFloat(spoPrice)} {`${currency.name}`}{' '}
            </span>
          </div>
          {isLoggedIn ? (
            <Button disabled={cantBuy} className='h-14 text-base font-semibold' onClick={handleClickCheckout}>
              BUY NOW
            </Button>
          ) : (
            <Button className='h-14 text-base font-semibold' onClick={onConnectWallet}>
              CONNECT WALLET
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default OokeengaINORightCard;
