import OokeengaINOLanding from 'views/OokeengaINO/OokeengaINOLanding';
import { lazy } from 'react';

const OokeengaINOOrder = lazy(() =>
  import('views/OokeengaINO').then((module) => ({ default: module.OokeengaINOOrder })),
);

export const routes = {
  home: {
    path: '/',
    component: OokeengaINOLanding,
    requiredLogin: true,
  },
  projectOrder: {
    path: '/order-history',
    component: OokeengaINOOrder,
    requiredLogin: true,
  },
};
