import { Button, DialogActions, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { amber } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(amber[300]),
  backgroundColor: amber[300],
}));

const PopupDone = memo(({ onClose }) => (
  <div className='box-border text-center text-white px-20 flex flex-col items-center justify-center'>
    <div className='bg-green-500 rounded-full w-[60px] h-[60px] flex items-center justify-center'>
      <Check className='text-4xl text-white' />
    </div>
    <Typography variant='h3' className='mt-12 mb-6'>
      SUCCESSFUL!
    </Typography>
    <Typography variant='body2' color='silver'>
      Your order has been successfully confirmed.
    </Typography>
    <DialogActions className='flex justify-center mt-6'>
      <ColorButton className='w-36 h-12 text-base' variant="contained" onClick={onClose}>
        Done
      </ColorButton>
    </DialogActions>
  </div>
));

export default PopupDone;
