import { compose } from 'ramda';
import { getProjectConfigByAlias, projectAlias } from 'data';
import { getClient } from './axios';

const accountServices = {};

export const createAccountService = (projectConfig) => {
  const { client } = getClient(projectConfig);

  const connect = (params) => client.get(`/login/wallet`, { params });
  const login = (body) => client.post(`/login`, body);

  const guestLogin = (body) => {
    return client.post(`/login-by-code`, body);
    // const p = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     if (body['login-code'] === '12345678') {
    //       resolve({
    //         message: 'success',
    //         code: 200,
    //       });
    //     } else {
    //       resolve({
    //         message: 'password is incorrect',
    //         code: 400,
    //       });
    //     }
    //   }, 2000);
    // });
    // return p;
  };

  accountServices[projectConfig.alias] = {
    connect,
    login,
    guestLogin,
  };
  return accountServices[projectConfig.alias];
};

export const getAccountService = (projectConfig) => {
  const { alias } = projectConfig;
  return accountServices[alias] || createAccountService(projectConfig);
};

export const accountService = compose(getAccountService, getProjectConfigByAlias)(projectAlias.defaultProject);
