import React from 'react';
import { AppFooter, AppHeader } from 'containers';

import styles from './PrivateLayout.module.scss';

export const PrivateLayout = ({ children }) => (
  <main className='min-h-screen flex flex-col'>
    <AppHeader />
    <div className={styles.contentContainer}>{children}</div>
    <AppFooter />
  </main>
);
