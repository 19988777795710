import { Loading } from 'components/Loading';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ServicesContextProvider } from 'services/ServicesContext';

const LoginPage = lazy(() => import('views/Login').then((module) => ({ default: module.LoginPage })));

const PreLoginRoute = () => {
  return (
    <ServicesContextProvider>
      <Suspense fallback={<Loading text='Loading, please wait...' />}>
        <Switch>
          <Route path='/' exact component={LoginPage} />

          {/* default redirect to login page */}
          <Redirect from='*' to='/' />
        </Switch>
      </Suspense>
    </ServicesContextProvider>
  );
};

export default PreLoginRoute;
