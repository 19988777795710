import { Button, DialogActions, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { memo } from 'react';
import { amber } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(amber[300]),
  backgroundColor: amber[300],
}));

const PopupFailed = memo(({ onClose }) => (
  <div className='box-border text-center text-white px-20 flex flex-col items-center justify-center'>
    <div className='bg-[#EC645E] rounded-full w-[60px] h-[60px] flex items-center justify-center'>
      <Close className='text-4xl text-white' />
    </div>
    <Typography variant='h3' className='mt-12 mb-6'>
      FAILED!
    </Typography>
    <Typography variant='body2' color='silver'>
      Your order has been rejected.
      <br />
      If you encounter any issues with your order, just send us a note at:{' '}
      <a href='mailto:customersupport@spores.app'>customersupport@spores.app</a>
    </Typography>
    <DialogActions className='flex justify-center mt-6'>
      <ColorButton className='w-36 h-12 text-base' variant="contained" onClick={onClose}>
        Done
      </ColorButton>
    </DialogActions>
  </div>
));

export default PopupFailed;
